var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Nuevo Administrador expositor ")])]),_c('v-card-text',{staticClass:"mt-10"},[_c('validation-observer',{ref:"form"},[_c('v-container',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 custom-col"},[_c('validation-provider',{attrs:{"name":"Nombre Completo","vid":"name","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre Completo","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1),_c('div',{staticClass:"col-12 custom-col"},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Email","required":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('div',{staticClass:"col-12 custom-col"},[_c('validation-provider',{attrs:{"name":"Confirmar Email","vid":"email_confirmation","rules":"required|email|max:80|confirmed:email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Confirmar Email","required":""},model:{value:(_vm.model.email_confirmation),callback:function ($$v) {_vm.$set(_vm.model, "email_confirmation", $$v)},expression:"model.email_confirmation"}})]}}])})],1),_c('div',{staticClass:"col-12 custom-col"},[_c('validation-provider',{attrs:{"name":"Empresa","vid":"companyId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.companies,"item-text":"name","item-value":"id","outlined":"","dense":"","error-messages":errors,"label":"Empresa","required":""},model:{value:(_vm.model.companyId),callback:function ($$v) {_vm.$set(_vm.model, "companyId", $$v)},expression:"model.companyId"}})]}}])})],1)])])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.saveAdminExpositorUser}},[_vm._v(" Aceptar ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }